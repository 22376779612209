import { useState, Dispatch, SetStateAction, useContext } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button/Button";
import { Log } from "../../interfaces/interfaces";
import moment from "moment";
import { UserContext } from "../../context/UserContext";
import { axiosInstance } from "../../utils/axiosInstance";
import { FaultStatus } from "../../interfaces/interfaces";
import AddStatusModal from "./AddStatusModal";
import Modal from "@mui/material/Modal";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #e63b11",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
    overflowY: "scroll",
};

//PROPS
interface Props {
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    log: Log;
    maintenanceRel: boolean;
    setMaintenanceRel: Dispatch<SetStateAction<boolean>>;
    faultStatuses?: FaultStatus[];
}

export default function MaintenanceModal(props: Props) {
    const [note, setNote] = useState("");
    const { user } = useContext(UserContext);
    const [selectedStatus, setSelectedStatus] = useState(0);
    //Alerts
    const [alert, setAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [disable, setDisable] = useState(false);
    //MODAL
    const [open, setOpen] = useState(false);

    const handleAlert = (message: string) => {
        setDisable(true);
        setMessage(message);
        setAlert(true);
        setTimeout(() => {
            setAlert(false);
            setMessage("");
            setDisable(false);
        }, 4000);
    };

    const handleStatusChange = () => {
        let data = {
            statusId: selectedStatus,
            faultId: props.log.faultId,
        };

        if (selectedStatus === 0) {
            handleAlert("Please select a status");
        } else {
            axiosInstance
                .put("/fault", data)
                .then((res) => {
                    handleAlert("Status Changed");
                    setTimeout(() => {
                        props.setMaintenanceRel(!props.maintenanceRel);
                        if (res.data.statusId === 5) {
                            props.setOpen(false);
                        }
                    }, 4000);
                })
                .catch((err) => {
                    console.log(err);
                    handleAlert("Error - Please try again");
                });
        }
    };

    const handleAddNote = () => {
        let data = {
            note: note,
            userId: user.userId,
            faultId: props.log.faultId,
        };
        if (note === "") {
            handleAlert("Please add a note");
        } else {
            axiosInstance
                .post("/fault/add-note", data)
                .then((res) => {
                    props.setMaintenanceRel(!props.maintenanceRel);
                    handleAlert("Note Added");
                    setNote("");
                })
                .catch((err) => {
                    console.log(err);
                    handleAlert("Error - Please try again");
                });
        }
    };
    return (
        <Box sx={style} className="maintenance_modal">
            <h3 className="modal_header">
                Fault ID: {props.log.faultId && props.log.faultId.slice(-12)}
            </h3>
            <section className="fault_details">
                <h3>Details</h3>
                <p>
                    Fault with <span>{props.log.reasonName}</span> @{" "}
                    <span>{props.log.locationName}</span>
                </p>
                <p>
                    Raised By <span>{props.log.loggedUserName}</span>{" "}
                    <span></span>
                    {props.log.createdAt != null &&
                    props.log.createdAt != undefined
                        ? ` @ ${moment(props.log.createdAt).format(
                              "DD-MM-YYYY HH:mm"
                          )}`
                        : ``}
                </p>
                <p></p>
                {props.log.machineName ? (
                    <p>
                        Machine : <span>{props.log.machineName}</span>
                    </p>
                ) : (
                    <></>
                )}
                {props.log.errorCode ? (
                    <p>Error Code : {props.log.errorCode}</p>
                ) : (
                    <></>
                )}

                <p>
                    <label>Description : </label>
                    {props.log.description}
                </p>
            </section>
            <section className="status_section">
                <div className="status_header">
                    <h3>Change status</h3>
                    <Button
                        variant="contained"
                        onClick={() => setOpen(true)}
                        disabled={disable}
                    >
                        Add New Status
                    </Button>
                </div>
                <p>
                    The current status is: <span>{props.log.statusName}</span>{" "}
                </p>
                <label>Change Status to: </label>
                <select
                    onChange={(e) =>
                        setSelectedStatus(parseInt(e.target.value))
                    }
                >
                    <option value={0}>Select a status</option>
                    {props.faultStatuses &&
                        props.faultStatuses.map((status, i) => {
                            return (
                                <option value={status.statusId}>
                                    {status.name}
                                </option>
                            );
                        })}
                </select>
                <Button
                    onClick={handleStatusChange}
                    color="success"
                    variant="contained"
                    disabled={disable}
                >
                    Save
                </Button>
            </section>{" "}
            {alert ? (
                <div className="alert_section">
                    <p>{message}</p>
                </div>
            ) : (
                <div className="no_alert"></div>
            )}
            <section className="note_section">
                <h3>Add a note</h3>
                <div className="note_box">
                    <textarea
                        onChange={(e) => setNote(e.target.value)}
                        value={note}
                        maxLength={200}
                    />
                    <Button
                        variant="contained"
                        onClick={handleAddNote}
                        disabled={disable}
                    >
                        Add
                    </Button>
                </div>

                {props.log.notes && props.log.notes.length > 0 ? (
                    <table>
                        <thead>
                            <tr>
                                {" "}
                                <th>Date</th>
                                <th>Note</th>
                                <th>Created By</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.log.notes.map((note, i) => {
                                return (
                                    <tr>
                                        <td>
                                            {moment(note.timeCreated).format(
                                                "DD-MM-YYYY HH:mm"
                                            )}
                                        </td>
                                        <td>{note.note}</td>
                                        <td>{note.userName}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                ) : (
                    <h3 className="no_notes">No notes yet</h3>
                )}
            </section>
            <section className="btn_section">
                <Button
                    variant="contained"
                    onClick={() => props.setOpen(false)}
                    disabled={disable}
                >
                    close
                </Button>
            </section>
            <Modal open={open}>
                <AddStatusModal
                    setOpen={setOpen}
                    maintenanceRel={props.maintenanceRel}
                    setMaintenanceRel={props.setMaintenanceRel}
                />
            </Modal>
        </Box>
    );
}
