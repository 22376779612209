import "./App.css";
import { useState, useContext } from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
    Outlet,
} from "react-router-dom";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import MaintenanceHome from "./components/maintenance/MaintenanceHome";
import HistoricHome from "./components/historic/HistoricHome";
import CleanerHome from "./components/cleaners/CleanerHome";
import Login from "./components/Login";
import { AuthContext, AuthProvider } from "./context/AuthContext";
import { UserContext } from "./context/UserContext";
import { axiosInstance } from "./utils/axiosInstance";
import NotFound from "./components/NotFound";

function App() {
    const [loggedIn, setLoggedIn] = useState(false);
    const [token, setToken] = useState("");
    const [user, setUser] = useState({
        roleId: 0,
        roleName: "",
        userId: "",
        userName: "",
    });

    const PrivateRoute = () => {
        let userStr = localStorage.getItem("userDetails");
        if (userStr) {
            setLoggedIn(true);
            return <Outlet />;
        } else {
            return <Navigate to="/login" replace />;
        }
    };

    return (
        <div className="App">
            <Router>
                {" "}
                <UserContext.Provider value={{ user }}>
                    <AuthProvider>
                        {loggedIn ? <Nav setLoggedIn={setLoggedIn} /> : null}

                        <Routes>
                            <Route
                                path="/login"
                                element={
                                    <Login
                                        setLoggedIn={setLoggedIn}
                                        setUser={setUser}
                                        setToken={setToken}
                                    />
                                }
                            />
                            <Route path="/" element={<PrivateRoute />}>
                                <Route
                                    path="/maintenance"
                                    element={<MaintenanceHome token={token} />}
                                />
                            </Route>
                            <Route path="/" element={<PrivateRoute />}>
                                <Route
                                    path="/historic"
                                    element={<HistoricHome token={token} />}
                                />
                            </Route>
                            <Route path="/" element={<PrivateRoute />}>
                                <Route
                                    path="/cleaners" 
                                    element={<CleanerHome/>} 
                                />
                            </Route>
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    </AuthProvider>{" "}
                    {loggedIn ? <Footer /> : null}
                </UserContext.Provider>
            </Router>
        </div>
    );
}

export default App;
