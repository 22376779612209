import "./styles/nav.scss";
import { useState, useContext } from "react";
import logo from "../images/footer_logo.webp";
import MobileLogo from "../images/square_logo.webp";
import { useNavigate } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Button } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { SetStateAction, Dispatch } from "react";
import { AuthContext } from "../context/AuthContext";

//PROPS
interface Props {
    setLoggedIn: Dispatch<SetStateAction<boolean>>;
}

export default function Nav(props: Props) {
    const navigate = useNavigate();
    const { authenticated, setAuthenticated } = useContext(AuthContext);
    //------------MENU SETUP-----------
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    //------------MENU SETUP-----------

    const logout = () => {
        localStorage.clear();
        props.setLoggedIn(false);
        setAuthenticated(false);
        navigate("/login");
    };

    return (
        <nav className="nav">
            <img src={logo} className="main_logo" />
            <img src={MobileLogo} className="mobile_logo" />
            <section className="menu_section">
                <p
                    className="menu_item"
                    onClick={() => navigate("/maintenance")}
                >
                    Maintenance
                </p>
                <p className="menu_item" onClick={() => navigate("/historic")}>
                    Historic
                </p>
                <p className="menu_item" onClick={() => navigate("/cleaners")}>
                    Cleaning
                </p>
                <p className="menu_item" onClick={logout}>
                    Logout
                </p>
            </section>
            <div className="mobile_menu">
                <Button
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                >
                    <MenuIcon className="mobile_menu_icon" />
                </Button>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        "aria-labelledby": "basic-button",
                    }}
                >
                    <MenuItem
                        onClick={() => {
                            handleClose();
                            navigate("/maintenance");
                        }}
                    >
                        Maintenance
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            handleClose();
                            navigate("/historic");
                        }}
                    >
                        Historic
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            handleClose();
                            navigate("/cleaners");
                        }}
                    >
                        Cleaners
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            handleClose();
                            logout();
                        }}
                    >
                        Logout
                    </MenuItem>
                </Menu>
            </div>
        </nav>
    );
}
