import axios from "axios";

//Creates an axios instance with preentered auth token from localstorage
export const GetAuthToken = () => {
    return localStorage.getItem("authToken");
};
// UPDATE AXIOS INTERCEPTOR TO CHECK FOR AUTH AND UPDATE HEADER
axios.interceptors.request.use(async (config) => {
    const token = await GetAuthToken();
    if (token != null) {
        config.headers.set("X-Auth-Token", token)
    }
    return config;
});


// UPDATE AXIOS INTERCEPTOR TO CHECK FOR AUTH AND UPDATE HEADER
axios.interceptors.response.use((response) => {
    console.log("RESP")
    console.log("REPONSE", response.status, typeof response.status)
    // const nav = useNavigate()

    if (response.status == 401) {
        window.location.href = '/login'
        return response;
    }

    return response;
});

// CREATE BASE INSTANCE OF AXIOS
const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        "Content-Type": "application/json",
    },
})

// UPDATE AXIOS INTERCEPTOR TO CHECK FOR AUTH AND UPDATE HEADER
axiosInstance.interceptors.request.use(async (config) => {
    const token = await GetAuthToken();
    if (token != null) {
        config.headers.set("X-Auth-Token", token)
    }
    return config;
});

// UPDATE AXIOS INTERCEPTOR TO CHECK RESPONSE FOR ERROR
axiosInstance.interceptors.response.use((response) => {
    return response;
}, (error) => {
    // IF UNAUTH THEN LOGIN
    if(error.response.status = 401)
        window.location.href = '/login'
 
});

export { axiosInstance }
