import { createContext, Dispatch, SetStateAction } from "react";
import { User } from "../interfaces/interfaces";

export type UserProps = {
    user: {
        roleId: number;
        roleName: string;
        userId: string;
        userName: string;
    };
};

export const UserContext = createContext<UserProps>({
    user: {
        roleId: 0,
        roleName: "",
        userId: "",
        userName: "",
    },
});
