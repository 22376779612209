import "./styles/notFound.scss";
import Logo from "../images/washingMachine.png";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
    const navigate = useNavigate();
    return (
        <main className="not_found">
            <img src={Logo} />
            <h1>You've ended up in the land of missing socks!</h1>
            <p>
                Click <span onClick={() => navigate("/login")}>here</span> to go
                back to login
            </p>
        </main>
    );
}
