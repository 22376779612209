import "./styles/login.scss";
import logo from "../images/SpeedQueen_WorldNo1InSelfServiceLaundry_LicenseStore_Reverse_CMYK.webp";
import { useState, useContext } from "react";
import { Button } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import bcrypt from "bcryptjs-react";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { SetStateAction, Dispatch } from "react";
import { AuthContext } from "../context/AuthContext";
import { User } from "../interfaces/interfaces";
import { axiosInstance } from "../utils/axiosInstance";

//PROPS
interface Props {
    setLoggedIn: Dispatch<SetStateAction<boolean>>;
    setUser: Dispatch<SetStateAction<User>>;
    setToken: Dispatch<SetStateAction<string>>;
}

export default function Login(props: Props) {
    const url = process.env.REACT_APP_API_URL;
    const userNameSalt = bcrypt.genSaltSync(10);
    const passwordSalt = bcrypt.genSaltSync(10);
    const navigate = useNavigate();
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");
    const [passwordToggle, setPasswordToggle] = useState(false);
    const { authenticated, setAuthenticated } = useContext(AuthContext);
    //Alerts
    const [alert, setAlert] = useState(false);
    const [message, setMessage] = useState("");

    const handleAlert = (message: string) => {
        setMessage(message);
        setAlert(true);
        setTimeout(() => {
            setAlert(false);
            setMessage("");
        }, 4000);
    };

    const login = async () => {
        const hashedUserName = bcrypt.hashSync(userName, userNameSalt);
        const hashedPassword = bcrypt.hashSync(password, passwordSalt);

        let loginData = {
            // username: hashedUserName,
            // password: hashedPassword,
            username: userName,
            password: password,
        };
  
        if (userName === "" || password === "") {
            handleAlert("Please enter both email & password");
        } else {
            axiosInstance
                .post(`/login`, loginData)
                .then((res) => {
                    localStorage.setItem("authToken", res.data.token);
                    props.setToken(res.data.token);

                    localStorage.setItem(
                        "userDetails",
                        JSON.stringify(res.data.user)
                    );

                    props.setLoggedIn(true);
                    props.setUser(res.data.user);
                    setAuthenticated(true);
                    navigate("/maintenance");
                })
                .catch((err) => {
                    console.log(err);
                    if (err.response) {
                        handleAlert(err.response.data);
                    } else {
                        handleAlert("Error - please try again");
                    }
                });
        }
    };

    const handleForgotPassword = () => {};
    return (
        <main className="login">
            <header className="header">
                <img src={logo} className="logo" />
            </header>
            <section className="login_section">
                <h3 className="login_header">Admin Login</h3>
                <div className="login_field">
                    <label>Username</label>
                    <input
                        type="text"
                        value={userName}
                        placeholder="Enter Username"
                        onChange={(e) => setUserName(e.target.value)}
                    />
                </div>
                <div className="login_field">
                    <label>Password</label>
                    <div className="search_area">
                        <input
                            type={passwordToggle ? "text" : "password"}
                            value={password}
                            placeholder="Enter password"
                            onChange={(e) => setPassword(e.target.value)}
                            onKeyDown={(e: any) => {
                                if (e.key === "Enter") {
                                    login();
                                }
                            }}
                        />
                        {passwordToggle ? (
                            <VisibilityIcon
                                className="search_btn"
                                onClick={() =>
                                    setPasswordToggle(!passwordToggle)
                                }
                            />
                        ) : (
                            <VisibilityOffIcon
                                className="search_btn"
                                onClick={() =>
                                    setPasswordToggle(!passwordToggle)
                                }
                            />
                        )}
                    </div>
                </div>
                <Button
                    variant="contained"
                    className="submit_btn"
                    onClick={login}
                >
                    Submit
                </Button>
                <p className="forgot_password">
                    Forgot your password?{" "}
                    <span onClick={handleForgotPassword}>click here</span>
                </p>
                {alert ? (
                    <div className="alert">
                        <p>{message}</p>
                    </div>
                ) : null}
            </section>
        </main>
    );
}
