//TYPESCRIPT INTERFACES

export interface User {
    roleId: number;
    roleName: string;
    userId: string;
    userName: string;
}

export interface Note {
    note: string | null;
    timeCreated: Date | null;
    userId: string | null;
    userName: string | null;
}

export interface Log {
    faultId?: string | null;
    locationName?: string;
    locationId: number;
    machineId: number | null;
    machineName?: string;
    reasonName?: string;
    reasonId: number;
    errorCode: string | null;
    description: string;
    statusName?: string;
    statusId: number;
    loggedUserName?: string | null;
    loggedUser: string;
    createdAt?: Date | null;
    notes?: Note[] | null;
}

export class Log implements Log {
    constructor() {}

    /**
     *
     * @param currentLogs Array Of Logs to be sorted
     * @param ascending Boolean to decide if Ascending / Descending
     * @returns Array Logs {Log[]}
     */
    public handleDateSort(currentLogs: Log[], ascending: boolean): Log[] {
        function getTime(date?: Date | null | undefined) {
            if (date != null && typeof date != "undefined") {
                return new Date(date).getTime();
            } else {
                return 0;
            }
        }

        function compare(a: any, b: any) {
            if (a == b) return 0;
            return a < b ? -1 : 1;
        }

        if (ascending) {
            return currentLogs.slice().sort((a: Log, b: Log) => {
                return compare(getTime(a.createdAt), getTime(b.createdAt));
            });
        } else {
            return currentLogs.slice().sort((a: Log, b: Log) => {
                return compare(getTime(b.createdAt), getTime(a.createdAt));
            });
        }
    }
}

export interface Location {
    locationId: number;
    locationPin: string;
    name: string;
}

export interface FaultReason {
    reasonId: number;
    reasonName: string;
}

export interface FaultStatus {
    statusId: number;
    name: string;
}

export interface Machine {
    machineId: number;
    name: string;
    LocationId: number;
}

export interface Filter {
    key: string;
    value: string;
}
