import { useState, Dispatch, SetStateAction } from "react";
import { Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import MaintenanceModal from "./MaintenanceModal";
import { Log } from "../../interfaces/interfaces";
import moment from "moment";
import { FaultStatus } from "../../interfaces/interfaces";
//PROPS
interface Props {
    log: Log;
    maintenanceRel: boolean;
    setMaintenanceRel: Dispatch<SetStateAction<boolean>>;
    faultStatuses?: FaultStatus[];
    historic?: boolean;
}

export default function MaintenanceLogIndv(props: Props) {
    const [open, setOpen] = useState(false);

    return (
        <>
            <tr>
                <td>{moment(props.log.createdAt).format("DD-MM-YY HH:mm")}</td>
                <td>{props.log.loggedUserName}</td>
                <td>{props.log.statusName}</td>
                <td>{props.log.faultId?.slice(-12)}</td>
                <td>{props.log.locationName}</td>
                {props.historic ? <td>{props.log.machineName}</td> : null}
                <td>
                    <Button
                        className="open_log_btn"
                        variant="contained"
                        onClick={() => setOpen(true)}
                    >
                        Open
                    </Button>
                </td>
            </tr>
            <Modal open={open}>
                <MaintenanceModal
                    setOpen={setOpen}
                    log={props.log}
                    maintenanceRel={props.maintenanceRel}
                    setMaintenanceRel={props.setMaintenanceRel}
                    faultStatuses={props.faultStatuses}
                />
            </Modal>
        </>
    );
}
