import "./styles/footer.scss";

export default function Footer() {
    return (
        <main className="footer">
            <p className="nitro_tag">
                Designed & Developed by{" "}
                <span className="nitro_link">
                    <a href="https://nitrosolutions.co.uk/" target="_blank">
                        Nitro Solutions
                    </a>
                </span>
            </p>
        </main>
    );
}
