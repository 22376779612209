import { useState } from "react";
import "./maintenance.scss";
import MaintenanceForm from "./MaintenanceForm";
import MaintenanceLogs from "./MaintenanceLogs";

interface Props {
    token: string;
}

export default function MaintenanceHome(props: Props) {
    const [maintenanceRel, setMaintenanceRel] = useState(false);

    return (
        <main className="maintenance">
            <MaintenanceForm
                token={props.token}
                maintenanceRel={maintenanceRel}
                setMaintenanceRel={setMaintenanceRel}
            />
            <MaintenanceLogs
                token={props.token}
                maintenanceRel={maintenanceRel}
                setMaintenanceRel={setMaintenanceRel}
            />
        </main>
    );
}
