import { useState, Dispatch, SetStateAction } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button/Button";
import { axiosInstance } from "../../utils/axiosInstance";

interface Props {
    setOpen: Dispatch<SetStateAction<boolean>>;
    maintenanceRel: boolean;
    setMaintenanceRel: Dispatch<SetStateAction<boolean>>;
}

const style = {
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "2px solid #e63b11",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
    width: 300,
};

export default function AddStatusModal(props: Props) {
    const [newStatus, setNewStatus] = useState("");
    //ALERTS
    const [alert, setAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [disable, setDisable] = useState(false);

    const handleAlert = (message: string) => {
        setDisable(true);
        setMessage(message);
        setAlert(true);
        setTimeout(() => {
            setAlert(false);
            setMessage("");
            setDisable(false);
        }, 4000);
    };

    const handleAddStatus = () => {
        if (newStatus === "") {
            handleAlert("Please enter a new status");
        } else {
            axiosInstance
                .post("/fault/status", { name: newStatus })
                .then((res) => {
                    handleAlert("New Status Created");
                    setTimeout(() => {
                        props.setMaintenanceRel(!props.maintenanceRel);
                        props.setOpen(false);
                    }, 4000);
                })
                .catch((err) => {
                    console.log(err);
                    handleAlert("Error - Please try again");
                });
        }
    };

    return (
        <Box sx={style} className="add_fault_modal">
            <h3 className="add_fault_header">Add New Status</h3>
            <section className="add_fault_section">
                <input
                    type="text"
                    onChange={(e) => setNewStatus(e.target.value)}
                    placeholder="Enter New Status"
                    maxLength={30}
                />

                <Button
                    variant="contained"
                    onClick={handleAddStatus}
                    disabled={disable}
                    color="success"
                >
                    Add
                </Button>
            </section>
            {alert ? (
                <div className="alert_area">
                    <p>{message}</p>
                </div>
            ) : (
                <div className="no_alert"></div>
            )}
            <section className="btn_section">
                <Button
                    onClick={() => {
                        props.setOpen(false);
                    }}
                    variant="contained"
                >
                    Close
                </Button>
            </section>
        </Box>
    );
}
