import { useState, useEffect, Dispatch, SetStateAction } from "react";
import MaintenanceLogIndv from "./MaintenanceLogIndv";
import { Button } from "@mui/material";
import { axiosInstance } from "../../utils/axiosInstance";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import { FaultStatus, Location, User, Log } from "../../interfaces/interfaces";
import Filter from "../Filter/Filter";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
interface Props {
    token: string;
    maintenanceRel: boolean;
    setMaintenanceRel: Dispatch<SetStateAction<boolean>>;
}

export default function MaintenanceLogs(props: Props) {
    // Bring in log class
    const log = new Log();
    // All Logs
    const [logs, setLogs] = useState<Log[]>([]);
    // Logs being displayed
    const [displayLogs, setDisplayLogs] = useState<Log[]>([]);
    const [faultStatuses, setFaultStatuses] = useState<FaultStatus[]>([]);
    const [dateAscending, setDateAscending] = useState(true);
    //Dialog Box
    const [openFilters, setOpenFilters] = useState(false);
    const [currentFilters, setCurrentFilters] = useState([]);

    useEffect(() => {
        //BRING IN FAULT STATUSES
        axiosInstance
            .get("/fault/status")
            .then((response) => {
                setFaultStatuses(response.data);
            })
            .catch((error) => console.error("Error:", error));
        axiosInstance
            .get("/fault/active")
            .then((response) => {
                setLogs(response.data);
                setDisplayLogs(response.data);
            })
            .catch((error) => console.error("Error:", error));
    }, [props.maintenanceRel]);

    // Hanlde updating Asceding and Sorting the Array
    const handleDateSort = () => {
        setDateAscending(!dateAscending);
        setDisplayLogs(log.handleDateSort(displayLogs, dateAscending));
    };

    return (
        <main className="maintenance_logs">
            <h3 className="logs_header">Maintenance Logs</h3>
            {logs.length > 0 ? (
                <>
                    <FilterAltIcon
                        className="filter_icon"
                        fontSize="large"
                        onClick={() => setOpenFilters(true)}
                    />

                    <Dialog
                        open={openFilters}
                        onClose={() => setOpenFilters(false)}
                    >
                        <DialogTitle sx={{ textAlign: "center" }}>
                            Filters
                        </DialogTitle>
                        <Filter
                            locationId={true}
                            loggedUserId={true}
                            statusId={true}
                            machineId={true}
                            data={logs}
                            setNewData={setDisplayLogs}
                            setCurrentFilters={setCurrentFilters}
                            currentFilters={currentFilters}
                            setMaintenanceRel={props.setMaintenanceRel}
                            maintenanceRel={props.maintenanceRel}
                            setOpenFilters={setOpenFilters}
                        />
                    </Dialog>

                    <section className="table_wrapper">
                        <table className="logs_table">
                            <thead>
                                <tr>
                                    <th
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            cursor: "pointer",
                                        }}
                                    >
                                        Date/Time
                                        <span>
                                            <SwapVertIcon
                                                onClick={() => handleDateSort()}
                                            />
                                        </span>
                                    </th>
                                    <th>Owner</th>
                                    <th>Status</th>
                                    <th>Reference</th>
                                    <th>Site</th>
                                    <th>Open</th>
                                </tr>
                            </thead>
                            <tbody>
                                {displayLogs.map((log, i) => {
                                    return (
                                        <MaintenanceLogIndv
                                            log={log}
                                            maintenanceRel={
                                                props.maintenanceRel
                                            }
                                            setMaintenanceRel={
                                                props.setMaintenanceRel
                                            }
                                            faultStatuses={faultStatuses}
                                        />
                                    );
                                })}
                            </tbody>
                        </table>
                    </section>
                </>
            ) : (
                <h3>There are no logs yet</h3>
            )}
        </main>
    );
}
