import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { Button } from "@mui/material";
import { axiosInstance } from "../../utils/axiosInstance";
import Modal from "@mui/material/Modal";
import {
    Location,
    FaultReason,
    FaultStatus,
    Machine,
    User,
} from "../../interfaces/interfaces";
import AddFaultModal from "./AddFaultModal";

interface Props {
    token: string;
    maintenanceRel: boolean;
    setMaintenanceRel: Dispatch<SetStateAction<boolean>>;
}

export default function MaintenanceForm(props: Props) {
    //ENV
    const url = process.env.REACT_APP_API_URL;
    const [user, setUser] = useState<User>({
        roleId: 0,
        roleName: "",
        userId: "",
        userName: "",
    });
    //FORM STATE
    const [locations, setLocations] = useState<Location[]>([]);
    const [faultReasons, setFaultReasons] = useState<FaultReason[]>([]);
    const [machines, setMachines] = useState<Machine[]>([]);
    const [selectedSiteId, setSelecedSiteId] = useState(0);
    const [selectedMachineNumber, setSelectedMachineNumber] = useState(0);
    const [selectedFaultReason, setSelectedFaultReason] = useState(0);
    const [errorCode, setErrorCode] = useState("");
    const [description, setDescription] = useState("");
    //ALERTS
    const [alert, setAlert] = useState(false);
    const [message, setMessage] = useState("");
    const [disable, setDisable] = useState(false);
    //MODAL
    const [open, setOpen] = useState(false);

    useEffect(() => {
        //REFRESH
        setDescription("");
        setErrorCode("");
        setSelectedFaultReason(0);
        setSelectedMachineNumber(0);
        setSelecedSiteId(0);
        //BRING IN USER FROM LOCALSTORAGE
        const userDetails = localStorage.getItem("userDetails");
        if (userDetails) {
            let parsedUserDetails = JSON.parse(userDetails);

            setUser(parsedUserDetails);
        }
        //BRING IN LOCATIONS
        axiosInstance
            .get("/location")
            .then((response) => {
                setLocations(response.data);
                setSelecedSiteId(response.data[0].locationId);
                axiosInstance
                    .get(`/machine/location/${response.data[0].locationId}`)
                    .then((response) => {
                        setSelectedMachineNumber(response.data[0].machineId);
                        setMachines(response.data);
                    })
                    .catch((error) => console.error("Error:", error));
            })
            .catch((error) => console.error("Error:", error));
        //BRING IN FAULT REASONS
        axiosInstance
            .get("/fault/reason")
            .then((response) => {
                setSelectedFaultReason(response.data[0].reasonId);
                setFaultReasons(response.data);
            })
            .catch((error) => console.error("Error:", error));
    }, [props.maintenanceRel]);

    const handleAlert = (message: string) => {
        setDisable(true);
        setMessage(message);
        setAlert(true);
        setTimeout(() => {
            setAlert(false);
            setMessage("");
            setDisable(false);
        }, 4000);
    };

    const handleSiteSelect = (siteId: number) => {
        setSelecedSiteId(siteId);

        axiosInstance
            .get(`/machine/location/${siteId}`)
            .then((response) => {
                setMachines(response.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const submitMaintenance = () => {
        if (selectedFaultReason != 1) {
            setErrorCode("");
            setSelectedMachineNumber(0);
        }

        let maintenanceData = {
            locationId: selectedSiteId,
            machineId: selectedFaultReason != 1 ? null : selectedMachineNumber,
            reasonId: selectedFaultReason,
            errorCode: errorCode == "" ? null : errorCode,
            description: description,
            statusId: 1,
            loggedUser: user.userId,
        };

        if (description === "") {
            handleAlert("Please provide a description");
        } else if (description.length < 25) {
            handleAlert("Please provide a more detailed description");
        } else {
            axiosInstance
                .post(`/fault`, maintenanceData)
                .then((res) => {
                    handleAlert("Successfully Submited");
                    setDisable(true);
                    setTimeout(() => {
                        props.setMaintenanceRel(!props.maintenanceRel);
                    }, 4000);
                })
                .catch((err) => {
                    console.log(err);
                    handleAlert("Error - Please try again");
                });
        }
    };

    return (
        <main className="maintenance_form">
            <h3 className="form_header">Maintenance Form</h3>
            <section className="form_section">
                <div>
                    <div className="input_field">
                        <label>Which site are you at?</label>
                        <select
                            onChange={(e) =>
                                handleSiteSelect(parseInt(e.target.value))
                            }
                        >
                            {locations &&
                                locations.map((location, i) => {
                                    return (
                                        <option
                                            key={i}
                                            value={location.locationId}
                                        >
                                            {location.name}
                                        </option>
                                    );
                                })}
                        </select>
                    </div>
                    <div className="input_field">
                        <label>What is the fault?</label>
                        <div className="fault_inner">
                            <select
                                onChange={(e) =>
                                    setSelectedFaultReason(
                                        parseInt(e.target.value)
                                    )
                                }
                            >
                                {faultReasons &&
                                    faultReasons.map((faultReason, i) => {
                                        return (
                                            <option
                                                value={faultReason.reasonId}
                                            >
                                                {faultReason.reasonName}
                                            </option>
                                        );
                                    })}
                            </select>
                            <Button
                                variant="contained"
                                disabled={disable}
                                onClick={() => setOpen(true)}
                                className="add_fault_btn"
                            >
                                Add New Fault
                            </Button>
                        </div>
                    </div>
                    {selectedFaultReason === 1 ? (
                        <>
                            <div className="input_field">
                                <label>What Machine number is it for?</label>

                                <select
                                    onChange={(e) =>
                                        setSelectedMachineNumber(
                                            parseInt(e.target.value)
                                        )
                                    }
                                >
                                    {machines.map((machine, i) => {
                                        return (
                                            <option key={i}
                                                value={machine.machineId}>
                                                {machine.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>

                            <div className="input_field">
                                <label>
                                    If there is an error code, what is it?
                                </label>
                                <input
                                    type="text"
                                    onChange={(e) =>
                                        setErrorCode(e.target.value)
                                    }
                                    value={errorCode}
                                    maxLength={30}
                                />
                            </div>
                        </>
                    ) : null}
                    <div className="input_field">
                        <label>
                            Please provide a description of the issue?
                        </label>
                        <textarea
                            rows={10}
                            cols={50}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            maxLength={200}
                        />
                    </div>

                    {alert ? (
                        <div className="alert_area">
                            <p>{message}</p>
                        </div>
                    ) : (
                        <div className="no_alert"></div>
                    )}
                </div>
                <Button
                    className="submit_btn"
                    variant="contained"
                    onClick={submitMaintenance}
                    disabled={disable}
                >
                    Submit
                </Button>
            </section>
            <Modal open={open}>
                <AddFaultModal
                    setOpen={setOpen}
                    maintenanceRel={props.maintenanceRel}
                    setMaintenanceRel={props.setMaintenanceRel}
                />
            </Modal>
        </main>
    );
}
