import { useState, useEffect, Dispatch, SetStateAction, useRef } from "react";
import { axiosInstance } from "../../utils/axiosInstance";
import {
    FaultStatus,
    Location,
    User,
    Log,
    Machine,
    Filter,
} from "../../interfaces/interfaces";
import "./filter.scss";
import { Button } from "@mui/material";

interface Props {
    locationId?: boolean;
    loggedUserId?: boolean;
    statusId?: boolean;
    machineId?: boolean;
    maintenanceRel?: boolean;
    data: any[];
    setNewData: Dispatch<SetStateAction<any[]>>;
    currentFilters: Filter[];
    setCurrentFilters: Dispatch<SetStateAction<never[]>>;
    setMaintenanceRel: Dispatch<SetStateAction<boolean>>;
    setOpenFilters: Dispatch<SetStateAction<boolean>>;
}

export default function MaintenanceLogs(props: Props) {
    const [currArray, setCurrArray] = useState<any[]>([]);

    // Select Lists
    const [sites, setSites] = useState<Location[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const [faultStatuses, setFaultStatuses] = useState<FaultStatus[]>([]);
    const [machines, setMachines] = useState<Machine[]>([]);

    //Ref
    const siteRef = useRef<HTMLSelectElement | null>(null);
    const ownerRef = useRef<HTMLSelectElement | null>(null);
    const statusRef = useRef<HTMLSelectElement | null>(null);
    const machineRef = useRef<HTMLSelectElement | null>(null);

    // Filter array
    const [filters, setFilters] = useState<any>([]);

    useEffect(() => {
        setCurrArray(props.data);
        setFilters(props.currentFilters);

        //SET REFS
        setTimeout(() => {
            for (let i = 0; i < props.currentFilters.length; i++) {
                if (props.currentFilters[i].key === "statusId") {
                    statusRef.current != null &&
                        (statusRef.current.value =
                            props.currentFilters[i].value);
                } else if (props.currentFilters[i].key === "locationId") {
                    siteRef.current != null &&
                        (siteRef.current.value = props.currentFilters[i].value);
                } else if (props.currentFilters[i].key === "loggedUser") {
                    ownerRef.current != null &&
                        (ownerRef.current.value =
                            props.currentFilters[i].value);
                } else if (props.currentFilters[i].key === "machineId") {
                    machineRef.current != null &&
                        (machineRef.current.value =
                            props.currentFilters[i].value);
                }
            }
        }, 500);

        if (props.locationId) {
            //BRING IN LOCATIONS
            axiosInstance
                .get("/location")
                .then((response) => {
                    setSites(response.data);
                })
                .catch((error) => console.error("Error:", error));
        }

        if (props.statusId) {
            //BRING IN FAULT STATUSES
            axiosInstance
                .get("/fault/status")
                .then((response) => {
                    setFaultStatuses(response.data);
                })
                .catch((error) => console.error("Error:", error));
        }

        if (props.loggedUserId) {
            //BRING IN USERS
            axiosInstance
                .get("/user")
                .then((response) => {
                    setUsers(response.data);
                })
                .catch((error) => console.error("Error:", error));
        }

        if (props.machineId) {
            //BRING IN MACHINES
            axiosInstance
                .get("/machine")
                .then((response) => {
                    setMachines(response.data);
                })
                .catch((error) => console.error("Error:", error));
        }
    }, [props.maintenanceRel, props.data]);

    // Function to Update Filters Array and the  Process new Filters
    const handleUpdateFilter = (key: string, value: string, _array: any[]) => {
        let _filters = filters;
        // Check if key already exists
        let findIndex = _filters.findIndex((filt: any) => filt.key == key);

        // If Default Value then remove filter
        if ((value == "0" || value == "") && findIndex >= 0) {
            _filters.splice(findIndex, 1);
        } else {
            // Push new filter or update existing
            if (findIndex >= 0) {
                _filters[findIndex].value = value;
            } else {
                _filters.push({ key: key, value: value });
            }
        }

        // Process Filters
        handleFilter(_filters, _array);
    };

    // Updates Dispaly Logs to show only Logs with applied Filters against them
    const handleFilter = (_filters: any, _array: any[]) => {
        // New Array to be passed back
        let nFilter = _array;
        // Looping through each of the filters, filter new array agains each value
        for (let index = 0; index < _filters.length; index++) {
            nFilter = nFilter.filter(
                (log: any) => log[filters[index].key] == filters[index].value
            );
        }
        // Update Filters Array and Display Array

        setFilters(_filters);
        props.setNewData(nFilter);
        props.setCurrentFilters(_filters);
    };

    return (
        <section className="filter_section">
            <section className="btn_section">
                <Button
                    onClick={() => {
                        props.setCurrentFilters([]);
                        props.setMaintenanceRel(!props.maintenanceRel);
                        props.setOpenFilters(false);
                    }}
                    className="clear_btn"
                    color="error"
                    variant="contained"
                >
                    Clear Filters
                </Button>
                <Button
                    className="close_btn"
                    variant="contained"
                    onClick={() => props.setOpenFilters(false)}
                >
                    Close Filters
                </Button>
            </section>

            {props.locationId && props.locationId ? (
                <div className="filter_input">
                    <label>Filter by Site</label>
                    <select
                        onChange={(e) => {
                            handleUpdateFilter(
                                "locationId",
                                e.target.value,
                                currArray
                            );
                        }}
                        ref={siteRef}
                    >
                        <option value={0}>Select a site</option>
                        {sites &&
                            sites.map((site: Location, i) => {
                                return (
                                    <option key={i} value={site.locationId}>
                                        {site.name}
                                    </option>
                                );
                            })}
                    </select>
                </div>
            ) : (
                <></>
            )}

            {props.loggedUserId && props.loggedUserId ? (
                <div className="filter_input">
                    <label>Filter by Owner</label>
                    <select
                        onChange={(e) =>
                            handleUpdateFilter(
                                "loggedUser",
                                e.target.value,
                                currArray
                            )
                        }
                        ref={ownerRef}
                    >
                        <option value={""}>Select an owner</option>
                        {users &&
                            users.map((user: User, i) => {
                                return (
                                    <option key={i} value={user.userId}>
                                        {user.userName}
                                    </option>
                                );
                            })}
                    </select>
                </div>
            ) : (
                <></>
            )}

            {props.statusId && props.statusId ? (
                <div className="filter_input">
                    <label>Filter by Status</label>
                    <select
                        onChange={(e) =>
                            handleUpdateFilter(
                                "statusId",
                                e.target.value,
                                currArray
                            )
                        }
                        ref={statusRef}
                    >
                        <option value={0}>Select a status</option>
                        {faultStatuses &&
                            faultStatuses.map(
                                (faultStatuse: FaultStatus, i) => {
                                    return (
                                        <option
                                            key={i}
                                            value={faultStatuse.statusId}
                                        >
                                            {faultStatuse.name}
                                        </option>
                                    );
                                }
                            )}
                    </select>
                </div>
            ) : (
                <></>
            )}

            {props.machineId && props.machineId ? (
                <div className="filter_input">
                    <label>Filter by Machine</label>
                    <select
                        onChange={(e) =>
                            handleUpdateFilter(
                                "machineId",
                                e.target.value,
                                currArray
                            )
                        }
                        ref={machineRef}
                    >
                        <option value={""}>Select an machine</option>
                        {users &&
                            machines.map((machine: Machine, i) => {
                                return (
                                    <option key={i} value={machine.machineId}>
                                        {machine.name}
                                    </option>
                                );
                            })}
                    </select>
                </div>
            ) : (
                <></>
            )}
        </section>
    );
}
